import 'bootstrap'

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Form from '@uppy/form';
import ActiveStorageUpload from 'uppy-activestorage-upload'
import * as UppyEnglish from '@uppy/locales/lib/en_US'
import * as UppyDutch from './uppy_nl'
import * as UppyGerman from '@uppy/locales/lib/de_DE'
import * as UppyFrench from '@uppy/locales/lib/fr_FR'
import * as UppyDanish from '@uppy/locales/lib/da_DK'
jQuery($ => {

    const $form = $('#lotselection-nav-terugmelden > form')
    const $modal = $('#lotselection-modal-terugmelden')
    const $submit = $('#lotselection-button-submit-terugmelden')
    const $cancel = $('#lotselection-button-cancel-terugmelden')
    const $summary = $('#lotselection-summary')
    const attachmentPreview = $("#attachment-preview")
    const attachmentPreviewModal = $("#attachment-preview-modal")
    const attachmentPreviewSpinner = $("#attachment-preview-spinner")

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('open_preview')) {
        attachmentPreview.hide();
        attachmentPreviewSpinner.show();

        const src = `/attachments/${urlParams.get('open_preview')}`
        const embed = $('<iframe>')
        embed.attr('src', `${src}#zoom=99`)
        embed.attr('width', '100%');
        embed.attr('height', '100%');
        embed.attr('type', 'application/pdf');

        attachmentPreview.html(embed);

        embed.on("load", () => {
            attachmentPreviewSpinner.hide();
            attachmentPreview.show();
        });

        attachmentPreviewModal.modal();
    }

    if ($form.length > 0) {
        let locale = UppyEnglish
        switch (document.documentElement.lang) {
            case "nl":
                locale = UppyDutch
                break;
            case "fr":
                locale = UppyFrench
                break;
            case "de":
                locale = UppyGerman
                break;
            case "da":
                locale = UppyDanish
                break;
        }

        const uppy = new Uppy({
            locale: locale
        }).use(ActiveStorageUpload, {
            directUploadUrl: document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
        }).use(Form, {
            target: "form",
            resultName: "cmr"
        }).use(Dashboard, {
            target: "#cmr_dashboard",
            inline: true,
            width: '100%',
            height: 300,
            hideUploadButton: true,
            proudlyDisplayPoweredByUppy: false
        })

        let confirmed = false;
        let uploaded = false;

        $form.on('submit', event => {
            if (uploaded) {
                return true
            }
            event.preventDefault();
            if (confirmed && !uploaded) {
                confirmed = false
                uppy.upload().then(() => {
                    uploaded = true
                    $modal.modal('hide')
                    $form.submit()
                })
            }
            $("#lotselection-no-data").addClass('d-none')
            $("#lotselection-confirmation").removeClass('d-none')
            $submit.prop('disabled', false)
            $form.find('[type="submit"]').prop('disabled', true)
            let confirmation = ""
            $form.find('.lot-selection-report').each((i, e) => {
                const $e = $(e)
                const quantity = $e.find('.quantity').val();
                if (!quantity || quantity <= 0) {
                    return true;
                }
                confirmation += `
                <li>
                    <b>${$e.find('.lot-selection-report-label').text()}</b>
                    <ul>
                        <li>
                            ${$e.find('.quantity-label').text()}: ${quantity}
                        </li>
                        <li>
                            ${$e.find('.pack-1-label').text()}: ${$e.find('.pack_1').val()}
                        </li>
                        <li>
                            ${$e.find('.pack-1-type-label').text()}: ${$e.find('.pack_1_type').val()}
                        </li>
                            ${$e.find('.own_bags').is(':checked') ? `<li> ${$e.find('.own-bags-label').text()}</li>` : ''}
                        <li>
                            ${$e.find('.pallet-qty-label').text()}: ${$e.find('.pallet_qty').val()}
                        </li>
                            ${$e.find('.ispm').is(':checked') ? `<li> ${$e.find('.ispm-label').text()}</li>` : ''}
                            ${$e.find('.own_pallets').is(':checked') ? `<li> ${$e.find('.own-pallets-label').text()}</li>` : ''}
                        <li>
                            ${$e.find('.pallet-type-label').text()}: ${$e.find('.pallet_type').val()}
                        </li>
                        <li>
                            ${$e.find('.remarks-label').text()}: ${$e.find('.remarks').val()}
                        </li>
                    </ul>
                </li>
                `
            })
            if (confirmation === "") {
                $("#lotselection-no-data").removeClass('d-none')
                $("#lotselection-confirmation").addClass('d-none')
                $submit.prop('disabled', true)
            } else {
                $summary.html(`<ul>${confirmation}</ul>`)
            }
            $modal
                .modal({
                    backdrop: 'static'
                })
                .modal('show');
        });

        $cancel.on('click', () => {
            $modal.modal('hide')
            $form.find('[type="submit"]').prop('disabled', false)
        });

        $submit.on('click', () => {
            confirmed = true;
            $form.submit()
        });

    }
})