jQuery(($) => {
  const attachmentsDatatable = $("#folder-attachments-datatable");
  const entityTables = $(".folder-entities-datatable");
  const attachmentPreview = $("#attachment-preview");
  const attachmentPreviewModal = $("#attachment-preview-modal");
  const attachmentPreviewSpinner = $("#attachment-preview-spinner");
  const selectFilters = $(".select2");
  const dateFilters = $(".daterange");
  let dataTables = [];
  let mobiScrollLocale = mobiscroll.localeEn;

  let language = {
    url: "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json",
  };

  switch (document.documentElement.lang) {
    case "nl":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json";
      mobiScrollLocale = mobiscroll.localeNl;
      break;
    case "fr":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json";
      mobiScrollLocale = mobiscroll.localeFr;
      break;
    case "de":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/German.json";
      mobiScrollLocale = mobiscroll.localeDe;
      break;
    case "da":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Danish.json";
      mobiScrollLocale = mobiscroll.localeDa;
      break;
  }

  attachmentsDatatable.dataTable({
    serverSide: true,
    responsive: true,
    ajax: {
      url: attachmentsDatatable.data("source"),
    },
    pagingType: "full_numbers",
    columns: [{ data: "name" }, { data: "action" }],
    columnDefs: [{ targets: 1, className: "text-center", orderable: false }],
    language,
  });

  selectFilters.each((i, elem) => {
    const input = $(elem);
    input.select2({
      placeholder: input.data("placeholder"),
      width: "100%",
      tags: true,
    });
  });

  selectFilters.on("change", (e) => {
    const table = dataTables.find(
      (i) => i.id === $(e.currentTarget).data("id")
    )?.table;
    table.draw();
  });

  dateFilters.each((i, elem) => {
    const input = $(elem);
    input.mobiscroll().datepicker({
      theme: "auto",
      themeVariant: "auto",
      locale: mobiScrollLocale,
      select: "range",
      onChange: (event) => {
        const table = dataTables.find((i) => i.id === input.data("id"))?.table;
        table.draw();
      },
    });
  });
  entityTables.each((i, elem) => {
    const table = $(elem);
    const id = table.data("id");
    const options = {
      serverSide: true,
      responsive: true,
      columnDefs: [
        { targets: "th-checkbox", orderable: false, className: "text-center" },
        { targets: "th-action", orderable: false, className: "text-center" },
        { targets: "property-type-currency", className: "text-right" },
        {
          targets: "property-uid-11270750_402",
          render: (data) => {
            let textColor = "text-muted";
            if (data === "Released" ||
                data === "Vrijgegeven" ||
                data === "Libéré" ||
                data === "Released for Delivery" ||
                data === "Vrijgegeven voor Transport" ||
                data === "Delivery In-Transit" ||
                data === "Transport In-Transit"
            ) {
              textColor = "text-warning";
            } else if (
              data === "In behandeling" ||
              data === "En traitement" ||
              data === "Pending Approval" ||
              data === "Delivery posted" ||
              data === "Verlading geboekt"
            ) {
              textColor = "text-secondary";
            } else if (
                data === "Finished" ||
                data === "Afgesloten" ||
                data === "Gereedgemeld" ||
                data === "Terminé"
            ) {
              textColor = "text-success";
            }
            return `<i class="fas fa-circle fa-xs ${textColor}"></i> <span>${data}</span>`;
          },
        },
        {
          targets: "property-uid-11270850_11270600",
          render: (data) => {
            let textColor = "text-warning";
            if (data === "Afgesloten" || data === "Finished") {
              textColor = "text-success";
            } else if (
              data === "Verlading geboekt" ||
              data === "Delivery posted"
            ) {
              textColor = "text-secondary";
            }
            return `<i class="fas fa-circle fa-xs ${textColor}"></i> <span>${data}</span>`;
          },
        },
      ],
      fnRowCallback: function (row, data) {
        if (data["read"] === "false") {
          $("td", row).each(function () {
            $(this).addClass("font-weight-bold");
          });
        }
      },
      order: [],
      ajax: {
        url: table.data("source"),
        data: (data) => {
          data.filters = {};
          $(`.select2[data-id=${id}]`).each((_, filter) => {
            const $filter = $(filter);
            const uid = $filter.data("uid");
            const values = $filter.val();
            if (values.length > 0) {
              data.filters[uid] = {
                type: "text",
                values: values,
              };
            }
          });
          $(`.daterange[data-id=${id}]`).each((_, filter) => {
            const $filter = $(filter);
            const uid = $filter.data("uid");
            const values = $filter.mobiscroll("getVal");
            if (values.length > 0) {
              data.filters[uid] = {
                type: "daterange",
                start_date: values[0],
                end_date: values[1],
              };
            }
          });
        },
      },
      pagingType: "full_numbers",
      language,
    };

    options.columns = table.find("th").map((i, elem) => {
      return { data: $(elem).data("uid") };
    });

    const dataTable = table.DataTable(options);
    dataTables.push({ id: table.data("id"), table: dataTable });
  });

  entityTables.on("click", ".preview-attachment", (e) => {
    attachmentPreview.hide();
    attachmentPreviewSpinner.show();

    const target = $(e.target);
    const title = target.parent().data("title");
    const src = target.parent().siblings().attr("href");

    const embed = $('<iframe>')
    embed.attr('src', `${src}#zoom=99`)
    embed.attr('width', '100%');
    embed.attr('height', '100%');
    embed.attr('type', 'application/pdf');

    attachmentPreview.html(embed);

    embed.on("load", () => {
      attachmentPreviewSpinner.hide();
      attachmentPreview.show();
    });

    attachmentPreviewModal.find(".modal-title").text(title);
    attachmentPreviewModal.modal();
  });

  $(".th-checkbox input[type=checkbox]").change((e) => {
    const checked = e.currentTarget.checked;
    $(".td-checkbox").each((i, e) => {
      e.checked = checked;
    });
  });

  $("#mark-as-read-button").on("click", (e) => {
    const table = dataTables.find(
      (i) => i.id === $(e.currentTarget).data("id")
    )?.table;
    const uids = $(".td-checkbox:checked")
      .map((i, e) => {
        return $(e).data("uid");
      })
      .toArray();

    if (uids && uids.length > 0 && table) {
      $.ajax({
        type: "POST",
        url: "mark_as_read",
        data: { uids: uids },
        success: () => {
          table.ajax.reload(null, false);
        },
      });
    }
  });

  $("#download-button").on("click", (e) => {
    const uids = $(".td-checkbox:checked")
      .map((i, e) => {
        return $(e).data("uid");
      })
      .toArray();

    if (uids && uids.length > 0) {
      $(e.target).prop("disabled", true);
      $(e.target)
        .find("i")
        .removeClass("fa-download")
        .addClass("fa-spinner fa-spin");
      $.ajax({
        type: "POST",
        url: "download",
        data: { uids: uids },
        xhrFields: {
          responseType: "blob",
        },
        success: function (blob, status, xhr) {
          $(e.target).prop("disabled", false);
          $(e.target)
            .find("i")
            .removeClass("fa-spinner fa-spin")
            .addClass("fa-download");
          // check for a filename
          var filename = "";
          var disposition = xhr.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1])
              filename = matches[1].replace(/['"]/g, "");
          }

          if (typeof window.navigator.msSaveBlob !== "undefined") {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              var a = document.createElement("a");
              // safari doesn't support this yet
              if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location.href = downloadUrl;
            }

            setTimeout(function () {
              URL.revokeObjectURL(downloadUrl);
            }, 100); // cleanup
          }
        },
      });
    }
  });
});
