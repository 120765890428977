jQuery($ => {
    $('#select-variety').select2({
        placeholder: $('#select-variety').data('placeholder'),
        width: '100%',
        tags: true
    });

    $('#select-klass').select2({
        placeholder: $('#select-klass').data('placeholder'),
        width: '100%',
        tags: true
    });

    $('#select-parcel').select2({
        placeholder: $('#select-parcel').data('placeholder'),
        width: '100%',
        tags: true
    });

    $('#select-size').select2({
        placeholder: $('#select-size').data('placeholder'),
        width: '100%',
        tags: true
    });

    $('#inventory-filters select').change(() => $('#inventory-filters').submit())
})