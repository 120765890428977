var nl_NL = {};
nl_NL.strings = {
    addMoreFiles: 'Extra bestanden toevoegen',
    addingMoreFiles: 'Bezig met extra bestanden toe te voegen',
    allowAccessDescription: 'Geef toestemming om foto\'s of videobeelden te kunnen maken.',
    allowAccessTitle: 'Geef toestemming om je camera te gebruiken',
    authenticateWith: 'Verbinden met %{pluginName}',
    authenticateWithTitle: 'Verbind met %{pluginName} om bestanden te selecteren',
    back: 'Terug',
    addMore: 'Meer toevoegen',
    browse: 'blader',
    browseFiles: 'blader',
    cancel: 'Annuleer',
    cancelUpload: 'Annuleer upload',
    chooseFiles: 'Kies bestanden',
    closeModal: 'Sluit Venster',
    companionError: 'Verbinding met Companion mislukt',
    complete: 'Voltooid',
    connectedToInternet: 'Verbonden met het internet',
    copyLink: 'Kopieer link',
    copyLinkToClipboardFallback: 'Kopieer de onderstaande URL',
    copyLinkToClipboardSuccess: 'Link naar klembord gekopieerd',
    creatingAssembly: 'Upload voorbereiden...',
    creatingAssemblyFailed: 'Transloadit: Kon Assembly niet creëeren',
    dashboardTitle: 'Uppy Dashboard',
    dashboardWindowTitle: 'Uppy Dashboard Venster (Druk escape om te sluiten)',
    dataUploadedOfTotal: '%{complete} van %{total}',
    done: 'Klaar',
    dropHereOr: 'Sleep hier je bestanden naar toe of %{browse}',
    dropHint: 'Sleep hier je bestanden naar toe',
    dropPasteBoth: 'Sleep hier je bestanden naar toe, plak of %{browse}',
    dropPasteFiles: 'Sleep hier je bestanden naar toe, plak of %{browse}',
    dropPasteFolders: 'Sleep hier je bestanden naar toe, plak of %{browse}',
    dropPasteImportBoth: 'Sleep hier je bestanden naar toe, plak, %{browse} of importeer vanuit',
    dropPasteImportFiles: 'Sleep hier je bestanden naar toe, plak, %{browse} of importeer vanuit',
    dropPasteImportFolders: 'Sleep hier je bestanden naar toe, plak, %{browse} of importeer vanuit',
    editFile: 'Bestand aanpassen',
    editing: 'Bezig %{file} aan te passen',
    emptyFolderAdded: 'Er werden geen bestanden toegevoegd uit de lege map',
    encoding: 'Coderen...',
    enterCorrectUrl: 'Ongeldige URL: Zorg dat je een directe link naar een bestand invoert',
    enterUrlToImport: 'Voeg URL toe om een bestand te importeren',
    exceedsSize2: 'Dit bestand overschrijdt de maximaal toegelaten bestandsgrootte van %{size}',
    exceedsSize: 'Dit bestand overschrijdt de maximaal toegelaten bestandsgrootte van',
    failedToFetch: 'Companion kan deze URL niet laden, controleer of de URL correct is',
    failedToUpload: 'Kon %{file} niet uploaden',
    fileSource: 'Bronbestand: %{name}',
    filesUploadedOfTotal: {
        '0': '%{complete} van %{smart_count} bestand geüpload',
        '1': '%{complete} van %{smart_count} bestanden geüpload'
    },
    filter: 'Filter',
    finishEditingFile: 'Klaar met bestand aan te passen',
    folderAdded: {
        '0': '%{smart_count} bestand uit %{folder} toegevoegd',
        '1': '%{smart_count} bestanden uit %{folder} toegevoegd'
    },
    import: 'Importeer',
    importFrom: 'Importeer vanuit %{name}',
    loading: 'Bezig met laden...',
    logOut: 'Uitloggen',
    myDevice: 'Mijn apparaat',
    noFilesFound: 'Geen bestanden of mappen gevonden',
    noInternetConnection: 'Geen internetverbinding',
    pause: 'Pauze',
    pauseUpload: 'Pauzeer upload',
    paused: 'Gepauzeerd',
    poweredBy2: 'Mogelijk gemaakt door %{uppy}',
    poweredBy: 'Mogelijk gemaakt door',
    processingXFiles: {
        '0': 'Bezig met %{smart_count} bestand te verwerken',
        '1': 'Bezig met %{smart_count} bestanden te verwerken'
    },
    recordingLength: 'Opnameduur %{recording_length}',
    recordingStoppedMaxSize: 'Opname gestopt omdat de bestandsgrootte de limiet bijna overschrijdt',
    removeFile: 'Bestand verwijderen',
    resetFilter: 'Filter resetten',
    resume: 'Hervatten',
    resumeUpload: 'Upload hervatten',
    retry: 'Opnieuw',
    retryUpload: 'Upload opnieuw',
    saveChanges: 'Wijzigingen opslaan',
    selectX: {
        '0': 'Selecteer %{smart_count}',
        '1': 'Selecteer %{smart_count}'
    },
    smile: 'Lach!',
    startRecording: 'Start video-opname',
    stopRecording: 'Stop video-opname',
    takePicture: 'Neem een foto',
    timedOut: 'Upload al gedurende %{seconds} seconden vastgelopen, bezig afbreken upload.',
    upload: 'Upload',
    uploadComplete: 'Upload voltooid',
    uploadFailed: 'Upload mislukt',
    uploadPaused: 'Upload gepauzeerd',
    uploadXFiles: {
        '0': 'Upload %{smart_count} bestand',
        '1': 'Upload %{smart_count} bestanden'
    },
    uploadXNewFiles: {
        '0': 'Upload +%{smart_count} bestand',
        '1': 'Upload +%{smart_count} bestanden'
    },
    uploading: 'Bezig met uploaden',
    uploadingXFiles: {
        '0': 'Bezig met %{smart_count} bestand te uploaden',
        '1': 'Bezig met %{smart_count} bestanden te uploaden'
    },
    xFilesSelected: {
        '0': '%{smart_count} bestand geselecteerd',
        '1': '%{smart_count} bestanden geselecteerd'
    },
    xMoreFilesAdded: {
        '0': '%{smart_count} extra bestand toegevoegd',
        '1': '%{smart_count} extra bestanden toegevoegd'
    },
    xTimeLeft: '%{time} over',
    youCanOnlyUploadFileTypes: 'Je kan enkel volgende types uploaden: %{types}',
    youCanOnlyUploadX: {
        '0': 'Je kan slechts %{smart_count} bestand uploaden',
        '1': 'Je kan slechts %{smart_count} bestanden uploaden'
    },
    youHaveToAtLeastSelectX: {
        '0': 'Je moet minstens %{smart_count} bestand selecteren',
        '1': 'Je moet minstens %{smart_count} bestanden selecteren'
    },
    selectAllFilesFromFolderNamed: 'Selecteer alle bestanden uit de map %{name}',
    unselectAllFilesFromFolderNamed: 'Deselecteer alle bestanden uit de map %{name}',
    selectFileNamed: 'Selecteer bestand %{name}',
    unselectFileNamed: 'Deselecteer bestand %{name}',
    openFolderNamed: 'Open map %{name}'
};

nl_NL.pluralize = function (n) {
    if (n === 1) {
        return 0;
    }

    return 1;
};

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
    window.Uppy.locales.nl_NL = nl_NL;
}

module.exports = nl_NL;