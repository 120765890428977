jQuery($ => {
    const checklistDatatable = $('#folder-checklist-answers-datatable');

    let language = {
        "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
    };

    switch (document.documentElement.lang) {
        case "nl":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
            break;
        case "fr":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json"
            break;
        case "de":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/German.json"
            break;
        case "da":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Danish.json";
            break;
    }

    const show_entity = checklistDatatable.data('show-entity');

    const columns = show_entity ?
        [
            {"data": "title"},
            {"data": "date_of_treatment"},
            {"data": "product"},
            {"data": "dosage"}
        ] :
        [
            {"data": "date_of_treatment"},
            {"data": "product"},
            {"data": "dosage"}
        ];

    const order = show_entity ? [[1, "desc"]] : [[0, "desc"]];

    checklistDatatable.dataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "ajax": {
            "url": checklistDatatable.data('source')
        },
        "pagingType": "full_numbers",
        columns,
        order,
        language
    });
});