jQuery(($) => {
  const documentsDatatable = $("#folder-documents-datatable");
  const attachmentPreview = $("#attachment-preview");
  const attachmentPreviewModal = $("#attachment-preview-modal");
  const attachmentPreviewSpinner = $("#attachment-preview-spinner");

  let language = {
    url: "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json",
  };

  switch (document.documentElement.lang) {
    case "nl":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json";
      break;
    case "fr":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json";
      break;
    case "de":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/German.json";
      break;
    case "da":
      language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Danish.json";
      break;
  }

  documentsDatatable.dataTable({
    serverSide: true,
    responsive: true,
    ajax: {
      url: documentsDatatable.data("source"),
    },
    pagingType: "full_numbers",
    columns: [{ data: "name" }, { data: "description" }, { data: "action" }],
    language,
  });

  documentsDatatable.on("click", ".preview-attachment", (e) => {
    attachmentPreview.hide();
    attachmentPreviewSpinner.show();

    const target = $(e.target);
    const title = target.parent().data("title");
    const src = target.parent().siblings().attr("href");

    const embed = $('<iframe>')
    embed.attr('src', `${src}#zoom=99`)
    embed.attr('width', '100%');
    embed.attr('height', '100%');
    embed.attr('type', 'application/pdf');

    attachmentPreview.html(embed);

    embed.on("load", () => {
      attachmentPreviewSpinner.hide();
      attachmentPreview.show();
    });

    attachmentPreviewModal.find(".modal-title").text(title);
    attachmentPreviewModal.modal();
  });
});
