import 'bootstrap'

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Form from '@uppy/form';
import ActiveStorageUpload from 'uppy-activestorage-upload'
import * as UppyEnglish from '@uppy/locales/lib/en_US'
import * as UppyDutch from './uppy_nl'
import * as UppyGerman from '@uppy/locales/lib/de_DE'
import * as UppyFrench from '@uppy/locales/lib/fr_FR'
import * as UppyDanish from '@uppy/locales/lib/da_DK'

jQuery($ => {

    const $form = $('#deellevering-nav-terugmelden > form')
    const $modal = $('#deellevering-modal-terugmelden')
    const $submit = $('#deellevering-button-submit-terugmelden')
    const $cancel = $('#deellevering-button-cancel-terugmelden')
    const $summary = $('#partial-deliver-summary')

    if ($form.length > 0) {
        let locale = UppyEnglish
        switch (document.documentElement.lang) {
            case "nl":
                locale = UppyDutch
                break;
            case "fr":
                locale = UppyFrench
                break;
            case "de":
                locale = UppyGerman
                break;
            case "da":
                locale = UppyDanish
                break;
        }

        const uppy = new Uppy({
            locale: locale
        }).use(ActiveStorageUpload, {
            directUploadUrl: document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
        }).use(Form, {
            target: "form",
            resultName: "cmr"
        }).use(Dashboard, {
            target: "#cmr_dashboard",
            inline: true,
            width: '100%',
            height: 300,
            hideUploadButton: true,
            proudlyDisplayPoweredByUppy: false
        })

        let confirmed = false;
        let uploaded = false;

        $form.on('submit', event => {
            if (uploaded) {
                return true
            }
            event.preventDefault();
            if (confirmed && !uploaded) {
                confirmed = false
                uppy.upload().then(() => {
                    uploaded = true
                    $modal.modal('hide')
                    $form.submit()
                })
            }
            $form.find('[type="submit"]').prop('disabled', true)
            let confirmation = ""
            $form.find('.lot-selection-report').each((i, e) => {
                const $e = $(e)
                confirmation += `
                <li>
                    ${$e.find('.lot-selection-report-label').text()}
                    <ul>
                        <li>
                            ${$e.find('.loaded-weight-label').text()}: ${$e.find('.loaded_weight').val()}
                        </li>
                    </ul>
                </li>
                `
            })
            $summary.html(`<ul>${confirmation}</ul>`)
            $modal
                .modal({
                    backdrop: 'static'
                })
                .modal('show');
        });

        $cancel.on('click', () => {
            $modal.modal('hide')
            $form.find('[type="submit"]').prop('disabled', false)
        });

        $submit.on('click', () => {
            confirmed = true;
            $form.submit()
        });

    }

    $('.amount-own-use[type=number]').on('change', e => {
        const target = $(e.target);
        const value = target.val();
        const uid = target.data('uid');
        const amount = $(`.amount[data-uid="${uid}"]`);
        amount.attr('min', value);
    });
})