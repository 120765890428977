jQuery($ => {
    const attachmentsDatatable = $('#entity-attachments-datatable');
    const entityTables = $('.entity-datatable');

    let language = {
        "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
    };

    switch (document.documentElement.lang) {
        case "nl":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
            break;
        case "fr":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json"
            break;
        case "de":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/German.json"
            break;
        case "da":
            language.url = "//cdn.datatables.net/plug-ins/1.10.21/i18n/Danish.json";
            break;
    }

    attachmentsDatatable.dataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "ajax": {
            "url": attachmentsDatatable.data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "name"},
            {"data": "action"}
        ],
        "columnDefs": [
            {"targets": 1, "className": "text-center", "orderable": false }
        ],
        language
    });

    entityTables.each((i, elem) => {
        const table = $(elem)
        const options = {
            "processing": true,
            "serverSide": true,
            "responsive": true,
            "columnDefs": [
                { orderable: false, targets: -1 }
            ],
            "order": [[ 0, "desc" ]],
            "ajax": {
                "url": table.data('source')
            },
            "pagingType": "full_numbers",
            language
        };

        options.columns = table.find('th').map((i, elem) => {
            return { "data": $(elem).data('uid') }
        })

        table.dataTable(options)
    });
});