import AutoNumeric from "autonumeric";

const setRequired = (element) => {
    const id = element.id.replace("amount", "treated");
    const $target = $(element);
    const $treated_true = $("#" + id + "_true");
    const $treated_false = $("#" + id + "_false");
    const required = $target.val() > 0;

    $treated_true.prop("required", required);
    $treated_false.prop("required", required);
};

const changeAmountType = (amountType) => {
    const visible = amountType === "custom";
    $("#bag-amount-group").toggle(visible);
    $("#bag-remarks").toggle(visible);
    const volumes = $('[data-property-id="11270751_32"]').find('.measurement');
    const amounts = $('[data-property-id="11270751_41"]').find('.measurement');
    volumes.each((i, e) => {
        const $e = $(e);
        const uid = $e.data('uid')
        if (visible) {
            // $(`.volume[data-uid="${uid}"]`).val("");
            AutoNumeric.set(`.volume[data-uid="${uid}"]`, "")
        } else {
            const volume = $e.text().trim().replace('.', '')
            // $(`.volume[data-uid="${uid}"]`).val(volume);
            AutoNumeric.set(`.volume[data-uid="${uid}"]`, volume)
        }
    });
    amounts.each((i, e) => {
        const $e = $(e);
        const uid = $e.data('uid')
        if (visible) {
            // $(`.number-of-packages[data-uid="${uid}"]`).val("");
            AutoNumeric.set(`.number-of-packages[data-uid="${uid}"]`, "")
        } else {
            const amount = $e.text().trim().replace('.', '')
            // $(`.number-of-packages[data-uid="${uid}"]`).val(amount);
            AutoNumeric.set(`.number-of-packages[data-uid="${uid}"]`, amount)
        }
    });
};

jQuery(($) => {
    const $form = $("#nav-bag-instructies form");
    const $modal = $('#opdracht-modal-terugmelden')
    const $submit = $('#opdracht-button-submit-terugmelden')
    const $cancel = $('#opdracht-button-cancel-terugmelden')
    const $summary = $('#opdracht-modal-info')

    $form.find(".amount").on("change", (e) => setRequired(e.target));
    $form.find(".amount").each((i, e) => setRequired(e));

    const $amount_type = $form.find(
        "input[type=radio][name=amount_type]"
    );
    const initial_amount_type = $form
        .find("input[type=radio][name=amount_type]:checked")
        .val();
    changeAmountType(initial_amount_type);
    $amount_type.on("change", (e) => {
        const $target = $(e.target);
        const val = $target.val();
        changeAmountType(val);
    });

    let confirmed = false;

    $form.on('submit', event => {
        if (confirmed) {
            return true
        }
        event.preventDefault();
        $form.find('[type="submit"]').prop('disabled', true)
        let confirmation = ""
        const volume_label = $("#bag-volume-label").text()
        const no_packages_label = $("#bag-no_packages-label").text()
        $form.find('.bag-title').each((i, e) => {
            const $e = $(e)
            const uid = $e.data('uid')
            const volume = $(`.volume[data-uid='${uid}']`).val()
            const no_packages = $(`.number-of-packages[data-uid='${uid}']`).val()
            if (volume > 0 || no_packages > 0) {
                confirmation += `
                <li>
                    ${$e.text()}
                    <ul>
                        <li>
                            ${volume_label}: ${volume}
                        </li>
                        <li>
                            ${no_packages_label}: ${no_packages}
                        </li>
                    </ul>
                </li>
                `
            }
        })
        $summary.html(`<ul>${confirmation}</ul>`)
        $modal
            .modal({
                backdrop: 'static'
            })
            .modal('show');
    });

    $cancel.on('click', () => {
        $modal.modal('hide')
        $form.find('[type="submit"]').prop('disabled', false)
    });

    $submit.on('click', () => {
        if ($modal.find('form')[0].checkValidity()) {
            const finished_state = $modal.find("input[type=radio]:checked").val()
            $form.find('#finished_state').val(finished_state)
            confirmed = true;
            $form.submit()
        }
    });
});
