window.$ = require('jquery');
window.jQuery = require('jquery');

import * as UJS from '@rails/ujs'

import 'popper.js'
import 'bootstrap'
import 'select2'

import AutoNumeric from 'autonumeric';

import * as mobiscroll from './mobiscroll.jquery.min';
import '../stylesheets/mobiscroll.jquery.min.css';

import "flag-icon-css/css/flag-icon.css"

// Load Datatables
// require('datatables.net-bs4')(window, $)
// require('datatables.net-buttons-bs4')(window, $)
// require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
// require('datatables.net-buttons/js/buttons.html5.js')(window, $)
// require('datatables.net-buttons/js/buttons.print.js')(window, $)
// require('datatables.net-responsive-bs4')(window, $)
// require('datatables.net-select-bs4')(window, $)

import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-responsive-bs4'
import 'datatables.net-select-bs4'

import './partial_delivery'
import './process_order'
import './folder'
import './entity'
import './documents'
import './box'
import './inventory'
import './lot_selection'

UJS.start()
global.Rails = UJS;
global.mobiscroll = mobiscroll

$.fn.select2.defaults.set("theme", "bootstrap");

jQuery($ => {
    AutoNumeric.multiple('.autonumeric', {
        allowDecimalPadding: false,
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        minimumValue: '0',
        maximumValue: '999999999',
        decimalPlaces : 0,
        unformatOnSubmit: true
    });

    const header = $('#app > header');
    const navbarToggle = header.find('.navbar-toggler');
    navbarToggle.on('click', () => header.toggleClass('open'));


    $('a[data-toggle="tab"]').on('click', e => {
        const hash = $(e.target).attr('href');
        if (history.pushState) {
            history.pushState(null, null, hash);
        } else {
            location.hash = hash;
        }
    });

    const hash = window.location.hash;
    if (hash) {
        ($('.nav-link[href="' + hash + '"]')).tab('show');
    }

    $(".clickable-row").on('click', (e) => {
        if (e.target.tagName === "TD") {
            window.location = $(e.currentTarget).data("href");
        }
    });
});